import * as React from "react";
import { PageProps } from "gatsby";
import ForgotPassword from "../components/user/ForgotPassword";
import MatomoTrack from "../components/user/MatomoTrack";

const ForgotPasswordRoute = (props: PageProps) => {
  return (
      <>
          <MatomoTrack />
          <ForgotPassword />
      </>
  );
};

export const Head = () => (
  <>
    <title>Runic | Forgot Password</title>
  </>
)

export default ForgotPasswordRoute;
